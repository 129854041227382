import React from 'react';
import BlogList from '../components/BlogList';
import { Helmet } from 'react-helmet';

const BlogPage = ({ posts }) => {
    return (
        <div className="blog-page">
            <Helmet>
                <title>Rocas y Minerales: Nombres de minerales, Qué son, Tipos, piedras y yacimientos geológicos</title>
                <meta name="description" content="Descubre todo sobre rocas y minerales en España, incluyendo un detallado mapa de yacimientos minerales. Información clave sobre minerales y rocas, tipos, formación, localización y minerales con nombres." />
            </Helmet>
            <main>
                <BlogList posts={posts} />
            </main>
        </div>
    );
};

export default BlogPage;

