import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import LandingPage from './pages/LandingPage';
import MapPage from './pages/MapPage';
import BlogPage from './pages/BlogPage';
import BlogPost from './components/BlogPost';
import Petrer from './asserts/Petrer.jpg'
import Tibi from './asserts/Tibi.jpg'
import Cofrentes from './asserts/Cofrentes.jpg'
import Orxeta from './asserts/Orxeta.jpeg'
import Finestrat from './asserts/Finestrat.jpeg'
import Mina from './asserts/Mina.png'
import Villamarchante from './asserts/montehorquera.png'
import Villarroya from './asserts/Villaroya.png'
import AvisoLegal from './components/AvisoLegal';
import TerminosCondiciones from './components/TerminosCondiciones';
import PoliticaPrivacidad from './components/PoliticaPrivacidad';
import PoliticaCookies from './components/PoliticaCookies';
import iddgnisita from './asserts/iddgnisita.jpg';
import azufre from './asserts/azufre.jpg'
import compostela from './asserts/jacinto.jpg'
import calcita from './asserts/calcita.jpg'
import yeso from './asserts/yeso.jpg'
import dolomita from './asserts/dolimta.jpg'
import amatista from './asserts/amatista.jpg'
import silex from './asserts/silex.jpg'
import cuarzo from './asserts/cuarzo.jpg'
import faro from './asserts/faro del albir.png'
import azuita from './asserts/azurita.png'
import oliverita from './asserts/oliverita.png'
import argonito from './asserts/argonitos.jpg'
import talco from './asserts/mineral5.jpeg'

import './styles/global.css';

const App = () => {

  const posts = [
    {
      id: 'Olivino y Iddingsita Volcán Cerro de Agras, Cofrentes',
      title: 'Olivino y Iddingsita Volcán Cerro de Agras, Cofrentes',
      date: '2024-8-5',
      image: Cofrentes,
      excerpt: 'Un volcán de gran importancia geológica, conocido por sus yacimientos de olivino e iddingsita',
      content: `
<h1>El Cerro de Agras: Un Tesoro Geológico en Cofrentes, Valencia</h1>

    <p>El <strong>Cerro de Agras</strong>, ubicado en la localidad de <strong>Cofrentes, Valencia</strong>, es uno de los <strong>volcanes más importantes</strong> de la península ibérica desde el punto de vista geológico. Este volcán, uno de los pocos que estuvieron activos en tiempos geológicos pasados, representa una evidencia clave del <strong>vulcanismo histórico en la región de Valencia</strong>. Su relevancia no solo radica en su actividad volcánica pasada, sino también en los <strong>valiosos yacimientos de minerales</strong> como el <strong>olivino</strong> y la <strong>iddingsita</strong>.</p>

    <h2>Olivino en el Cerro de Agras: Un Mineral de Belleza y Ciencia</h2>
    <p>El <strong>olivino</strong> es uno de los minerales más representativos de las rocas ígneas, especialmente en basaltos. Su característico <strong>color verde oliva</strong> y su <strong>estructura cristalina</strong> lo convierten en una pieza codiciada tanto por <strong>geólogos</strong> como por <strong>coleccionistas de minerales</strong>. En el Cerro de Agras, el olivino aparece en <strong>cristales bien formados</strong>, siendo uno de los principales atractivos del lugar.</p>

    <p>El hallazgo de olivino en este volcán no solo aporta valor estético, sino también científico, ya que permite comprender los procesos internos de la Tierra y la <strong>formación de rocas volcánicas</strong>. El olivino se forma a grandes profundidades y emerge durante las erupciones, ofreciendo pistas sobre las condiciones del manto terrestre.</p>

    <h2>Iddingsita: El Testimonio de la Alteración Geológica</h2>
    <p>La <strong>iddingsita</strong> es un mineral menos común que el olivino y se forma a partir de la <strong>alteración del olivino</strong> en condiciones de baja temperatura e hidratación. Este proceso post-eruptivo convierte al olivino en un mineral terroso de <strong>color marrón o rojizo</strong>, una característica distintiva en las zonas del Cerro de Agras donde el olivino ha sido alterado.</p>

    <p>La presencia de iddingsita en Cofrentes proporciona una ventana única a los procesos geológicos de <strong>transformación mineral</strong> y a las interacciones entre el magma y su entorno. Este mineral actúa como un <strong>indicador geológico clave</strong>, señalando las condiciones de enfriamiento y las <strong>dinámicas hidráulicas</strong> que han moldeado la región durante milenios.</p>

    <h2>Importancia Científica y Turística del Cerro de Agras</h2>
    <p>El Cerro de Agras no solo es relevante para la comunidad científica, sino también para el <strong>turismo geológico</strong>. Visitantes de todo el mundo acuden a Cofrentes para explorar este antiguo volcán y sus formaciones minerales únicas. Las rutas de senderismo y las visitas guiadas permiten apreciar de cerca las formaciones de olivino e iddingsita, convirtiéndolo en un <strong>destino imprescindible</strong> para los amantes de la naturaleza y la geología.</p>

    <h2>Un Destino Único en la Comunidad Valenciana</h2>
    <p>Explorar el Cerro de Agras es adentrarse en la <strong>historia volcánica de España</strong>. Su importancia mineralógica, junto con su belleza natural, hacen de este volcán un lugar perfecto para actividades educativas, de ocio y de investigación. Si buscas una experiencia diferente y fascinante en la Comunidad Valenciana, el Cerro de Agras es, sin duda, una <strong>parada obligatoria</strong>.</p>

    <p>Con esta riqueza geológica y su valor turístico, el Cerro de Agras representa un testimonio vivo de los procesos volcánicos que dieron forma a la península ibérica, consolidándose como un referente de interés tanto local como internacional.</p>
`
    },
    {

      id: 'Jacintos de compostela en la carretera de Orxeta-Finestrat',
      title: 'Jacintos de compostela en la carretera de Orxeta-Finestrat',
      date: '2024-03-15',
      image: Finestrat,
      excerpt: 'Una variedad de cuarzo rojo que debe su coloración a la presencia de óxidos de hierro',
      content: `
           <h1>Jacintos de Compostela en la Carretera Orxeta-Finestrat: Un Tesoro Mineralógico de Alicante</h1>

<p>A lo largo de la carretera que conecta <strong>Orxeta y Finestrat</strong>, en la provincia de <strong>Alicante</strong>, se encuentran ricos yacimientos de <strong>Jacintos de Compostela</strong>. Estos cristales, una variedad de <strong>cuarzo rojo</strong> que debe su coloración a la presencia de óxidos de hierro, son altamente apreciados tanto por su belleza como por su valor mineralógico.</p>

<p>Los Jacintos de Compostela se forman en <strong>rocas sedimentarias</strong>, principalmente en arenas y arcillas, donde los procesos de cementación y oxidación les confieren su característico <strong>tono rojizo</strong>, que puede variar desde un <strong>naranja intenso</strong> hasta un <strong>rojo oscuro</strong>. Estos cristales suelen tener un <strong>hábito prismático</strong>, con caras bien definidas y bordes afilados, lo que les otorga un gran atractivo visual.</p>

<h2>Un Destino Codiciado por Coleccionistas y Geólogos</h2>

<p>La <strong>carretera de Orxeta-Finestrat</strong> es conocida entre <strong>coleccionistas y geólogos</strong> por la calidad excepcional de los Jacintos de Compostela que se encuentran en la zona. Los ejemplares extraídos aquí son valorados por su <strong>tamaño, brillo y color</strong>, haciéndolos muy deseados en el ámbito de la mineralogía.</p>

<h2>Historia y Valor Cultural</h2>

<p>Históricamente, estos cristales han sido recolectados no solo por su belleza, sino también por su supuesta capacidad de <strong>atraer la buena suerte</strong>, lo que les ha conferido un <strong>valor cultural</strong> además del científico. Aunque la extracción en esta área ha sido a pequeña escala, los Jacintos de Compostela de Orxeta-Finestrat han contribuido al <strong>conocimiento geológico regional</strong>, permitiendo estudiar los procesos sedimentarios y diagenéticos que dan lugar a la formación de estos cristales.</p>

<p>La riqueza mineralógica de esta región no solo atrae a especialistas, sino también a turistas y aficionados que buscan conocer más sobre la geología local. Sin duda, los Jacintos de Compostela son un <strong>tesoro natural</strong> que sigue cautivando a generaciones de exploradores y científicos.</p>
`
    },
    {
      id: 'Argonitos en Loma Badá, Petrer',
      title: 'Argonitos en Loma Badá, Petrer',
      date: '2024-07-15',
      image: Petrer,
      excerpt: 'El aragonito es una de las tres formas cristalinas en las que se puede encontrar el carbonato de calcio',
      content: `
            <h1>Aragonito en Loma Badá: Un Tesoro Mineralógico en Petrer, Alicante</h1>

<p><strong>Loma Badá</strong>, ubicada en la localidad de <strong>Petrer</strong>, en la provincia de <strong>Alicante</strong>, es conocida por sus yacimientos de minerales, en particular de <strong>aragonito</strong>. El aragonito es una de las tres formas cristalinas en las que se puede encontrar el <strong>carbonato de calcio</strong>, siendo las otras dos la <strong>calcita</strong> y la <strong>vaterita</strong>.</p>

<p>Este mineral se caracteriza por su <strong>estructura ortorrómbica</strong> y su <strong>hábito cristalino</strong>, que a menudo forma <strong>cristales aciculares o prismáticos</strong>. En <strong>Loma Badá</strong>, el aragonito se presenta en una variedad de formas y colores, que van desde el <strong>blanco puro</strong> hasta tonos más oscuros, dependiendo de las impurezas presentes durante su formación.</p>

<h2>Un Destino de Interés para Coleccionistas y Geólogos</h2>

<p>Los yacimientos en esta zona son de particular interés para los <strong>coleccionistas y geólogos</strong> debido a la calidad y el tamaño de los cristales, que pueden alcanzar <strong>dimensiones considerables</strong> y mostrar una <strong>transparencia notable</strong>.</p>

<h2>Importancia Mineralógica y Geológica</h2>

<p>La <strong>extracción del aragonito</strong> en Loma Badá ha sido objeto de estudio tanto por su valor mineralógico como por su <strong>importancia en la geología regional</strong>. Este mineral se forma en ambientes geológicos específicos, generalmente en condiciones de <strong>baja temperatura y alta presión</strong>, lo que lo convierte en un <strong>indicador clave de procesos geológicos</strong> en la región.</p>

<p>El aragonito de Loma Badá continúa atrayendo la atención de expertos y aficionados, consolidando a esta región como un lugar imprescindible para quienes buscan conocer más sobre la <strong>riqueza geológica</strong> de la provincia de Alicante.</p>
`
    },
    {
      id: 'Azufre en la mina San Francisco, Tibi',
      title: 'Azufre en la mina San Francisco, Tibi',
      date: '2024-08-10',
      image: Tibi,
      excerpt: 'El azufre en su forma nativa, se caracteriza por su distintivo color amarillo y su fragilidad.',
      content: `
            <h1>La Mina San Francisco en Tibi: Un Yacimiento de Azufre de Gran Valor</h1>

<p>La <strong>mina San Francisco</strong>, ubicada en <strong>Tibi, Alicante</strong>, es un sitio de notable relevancia debido a sus ricos yacimientos de <strong>azufre</strong>. Este mineral, fundamental en diversas industrias, se presenta en esta mina en su <strong>forma nativa</strong>, caracterizada por su distintivo <strong>color amarillo</strong> y su <strong>fragilidad</strong>.</p>

<p>El azufre de la mina San Francisco ha sido históricamente importante tanto para la <strong>economía local</strong> como para <strong>estudios geológicos</strong>. Su presencia en Tibi se debe a procesos <strong>geotérmicos y volcánicos</strong> que facilitaron la acumulación de azufre en esta zona, lo que convierte a la mina en un lugar de interés para <strong>geólogos y mineralogistas</strong>.</p>

<h2>Pureza y Aplicaciones del Azufre de Tibi</h2>

<p>Los yacimientos de azufre en la <strong>mina San Francisco</strong> se distinguen por la <strong>pureza del mineral</strong>, que en algunos casos alcanza niveles elevados, haciendo que los cristales sean muy apreciados. Además, este azufre ha sido utilizado tradicionalmente en la <strong>producción de ácido sulfúrico</strong>, en la <strong>vulcanización del caucho</strong>, y en la <strong>fabricación de productos químicos</strong>.</p>

<h2>Importancia Geológica y Económica</h2>

<p>La presencia de azufre en esta región no solo ha contribuido al desarrollo industrial, sino que también ha permitido un mayor entendimiento de los <strong>procesos geológicos locales</strong>. La mina San Francisco sigue siendo un referente para estudios relacionados con <strong>yacimientos minerales</strong> y formación de <strong>depósitos volcánicos</strong>.</p>

<p>Visitar o estudiar la mina San Francisco es una oportunidad para conocer más sobre la <strong>riqueza mineralógica</strong> de la provincia de Alicante, consolidando a Tibi como un destino clave para los amantes de la geología y la mineralogía.</p>
 `
    },
    {
      id: 'Calcita en el deposito de Keuper, Carretera Orxeta-Finestrat',
      title: 'Calcita en el deposito de Keuper, Carretera Orxeta-Finestrat',
      date: '2024-04-05',
      image: Orxeta,
      excerpt: 'Calcita, un mineral de los más comunes en la corteza terrestre, destacan por su pureza y brillo',
      content: `
            <h1>Depósito de Keuper en Orxeta-Finestrat: Yacimiento de Calcita en Alicante</h1>

<p>El <strong>depósito de Keuper</strong>, situado a lo largo de la carretera que conecta <strong>Orxeta y Finestrat</strong> en la provincia de <strong>Alicante</strong>, es conocido por sus yacimientos de <strong>calcita</strong>. Este mineral, uno de los más comunes en la corteza terrestre, se presenta en este lugar en forma de <strong>cristales bien formados</strong> que destacan por su <strong>pureza y brillo</strong>.</p>

<p>La <strong>calcita</strong> es un mineral de <strong>carbonato de calcio</strong> que se encuentra en una amplia variedad de formas y colores, dependiendo de las impurezas y las condiciones de formación. En el <strong>depósito de Keuper</strong>, la calcita aparece típicamente en tonos que van desde el <strong>blanco translúcido</strong> hasta el <strong>amarillo pálido</strong>, con cristales que pueden adoptar formas <strong>romboédricas o escalenoédricas</strong>, las cuales son características de este mineral.</p>

<h2>Formación Geológica del Depósito de Keuper</h2>

<p>El depósito de Keuper es parte de una formación geológica más amplia que se desarrolló durante el <strong>período Triásico</strong>, cuando se acumularon grandes cantidades de sedimentos en condiciones <strong>marinas y lacustres</strong>. La calcita en esta zona se originó principalmente a partir de la <strong>precipitación química de carbonato de calcio</strong> en ambientes evaporíticos, lo que ha dado lugar a los <strong>depósitos masivos</strong> que se observan hoy en día.</p>

<h2>Interés Geológico y Valor Industrial</h2>

<p>Este yacimiento es de especial interés tanto para <strong>geólogos</strong> como para <strong>coleccionistas</strong>, ya que ofrece una excelente oportunidad para estudiar los <strong>procesos sedimentarios y diagenéticos</strong> que ocurren en ambientes evaporíticos. Además, la calcita extraída de esta área es valorada por su uso en la <strong>industria</strong>, especialmente en la <strong>producción de cemento, cal y otros materiales de construcción</strong>.</p>

<p>El depósito de Keuper continúa siendo una referencia clave para el estudio de formaciones geológicas del Triásico y representa un recurso natural importante para la región de Alicante.</p>
 `
    },
    {
      id: "Azurita y Olivenita Monte Horquera, Villamarchante",
      title: "Azurita y Olivenita Monte Horquera, Villamarchante",
      date: "2024-9-14",
      image: Villamarchante,
      excerpt: "Un yacimiento mineral excepcional con abundancia de azurita y olivenita en Monte Horquera, Villamarchante.",
      content: `
      <h1>Monte Horquera en Villamarchante: Yacimientos de Azurita y Olivenita</h1>

<p>El <strong>Monte Horquera</strong>, ubicado en <strong>Villamarchante, Valencia</strong>, es reconocido por sus yacimientos minerales, especialmente por la presencia de <strong>azurita</strong> y <strong>olivenita</strong>. Este sitio es una referencia geológica importante debido a la <strong>riqueza y diversidad</strong> de los minerales que se pueden encontrar en su entorno.</p>

<h2>Azurita: El Tesoro Azul de Monte Horquera</h2>

<p>La <strong>azurita</strong>, un mineral de <strong>cobre</strong> caracterizado por su distintivo <strong>color azul profundo</strong>, se forma en <strong>zonas de oxidación</strong> de los depósitos de cobre. En Monte Horquera, la azurita se presenta en <strong>cristales bien desarrollados</strong> y en <strong>masas nodulares</strong>, lo que la convierte en un atractivo para <strong>coleccionistas</strong> y estudiosos de la <strong>mineralogía</strong>.</p>

<h2>Olivenita: Un Mineral Raro pero Valioso</h2>

<p>La <strong>olivenita</strong>, otro mineral de <strong>cobre</strong>, es menos común pero igualmente apreciada. Se reconoce por su <strong>color verde oliva</strong> o marrón, y es un producto de la <strong>alteración hidrotermal</strong> de otros minerales de cobre. En <strong>Villamarchante</strong>, la olivenita aparece en asociaciones con azurita, lo que indica un ambiente de formación similar y un <strong>ciclo de alteración</strong> en el yacimiento.</p>

<h2>Importancia Geológica y Mineralógica</h2>

<p>Estos minerales no solo tienen <strong>valor estético</strong>, sino que proporcionan información crucial sobre los <strong>procesos de oxidación</strong> y <strong>alteración hidrotermal</strong> que han tenido lugar en la región. El estudio de la <strong>azurita</strong> y la <strong>olivenita</strong> en Monte Horquera ofrece una ventana a la <strong>evolución geológica y mineralógica</strong> de este sector de la <strong>Comunidad Valenciana</strong>.</p>

<p>Monte Horquera continúa atrayendo a expertos y aficionados, consolidándose como un destino esencial para aquellos interesados en la <strong>riqueza mineralógica</strong> de la provincia de Valencia.</p>
`
    },
    {
      id: "Minerales Mina de San Vicente, Luchena",
      title: "Minerales Mina de San Vicente, Luchena",
      date: "2024-7-18",
      image: Mina,
      excerpt: "Un yacimiento mineral histórico conocido por su diversidad, incluyendo galena, esfalerita y calcopirita en la Mina de San Vicente, Luchena.",
      content: `
    <h1>Mina de San Vicente en Luchena: Un Yacimiento Histórico de Castellón</h1>

<p>La <strong>Mina de San Vicente</strong>, ubicada en <strong>Luchena, Castellón</strong>, es uno de los yacimientos históricos más importantes de la región, conocida por su riqueza en minerales como la <strong>galena</strong>, <strong>esfalerita</strong> y <strong>calcopirita</strong>. Este lugar ha sido una fuente significativa de extracción mineral desde la antigüedad.</p>

<h2>Galena: El Mineral Predominante</h2>

<p>La <strong>galena</strong>, un <strong>sulfuro de plomo</strong>, es el mineral más abundante en la mina y destaca por su <strong>brillo metálico</strong> y su <strong>alta densidad</strong>. Se ha explotado durante siglos por su contenido de <strong>plomo y plata</strong>, y en la Mina de San Vicente se presenta en <strong>grandes masas cristalinas</strong>.</p>

<h2>Esfalerita: Belleza y Valor Industrial</h2>

<p>La <strong>esfalerita</strong>, un <strong>sulfuro de zinc</strong>, se encuentra comúnmente asociada a la galena en este yacimiento. Reconocida por su <strong>color variable</strong>, que va del <strong>amarillo al marrón oscuro</strong>, la esfalerita es valorada no solo por su contenido en zinc, sino también por su <strong>belleza cristalina</strong>.</p>

<h2>Calcopirita: El Oro de los Tontos</h2>

<p>La <strong>calcopirita</strong>, un mineral de <strong>cobre y hierro</strong>, completa el trío de minerales principales de la mina. Su <strong>color dorado metálico</strong> le ha valido el apodo de "<strong>oro de los tontos</strong>", y su presencia en Luchena es indicativa de la <strong>intensa actividad hidrotermal</strong> que originó el yacimiento.</p>

<h2>Importancia Geológica y Minera</h2>

<p>Estos minerales, además de ser valiosos para la minería, ofrecen una importante perspectiva sobre los <strong>procesos geológicos</strong> que dieron forma a la Mina de San Vicente. Su estudio continúa siendo clave para comprender la <strong>evolución minera</strong> de la región y sus características geológicas.</p>

<p>La Mina de San Vicente sigue siendo un destino de interés para <strong>geólogos</strong>, <strong>coleccionistas</strong> y amantes de la mineralogía, consolidándose como un referente en la historia minera de Castellón.</p>
`
    },
    {
      id: "Yacimiento Barbalimpia, Villarroya de los Pinares",
      title: "Yacimiento Barbalimpia, Villarroya de los Pinares",
      date: "Cretácico",
      image: Villarroya,
      excerpt: "Un importante yacimiento fósil en Villarroya de los Pinares, conocido por su diversidad de invertebrados marinos.",
      content: `<h1>Yacimiento Fósil de Barbalimpia en Villarroya de los Pinares, Teruel</h1>

<p>El <strong>yacimiento fósil de Barbalimpia</strong>, ubicado en <strong>Villarroya de los Pinares, Teruel</strong>, es reconocido por su riqueza en fósiles del periodo <strong>Cretácico</strong>. Este sitio ha proporcionado una valiosa colección de fósiles de <strong>invertebrados marinos</strong>, incluyendo <strong>bivalvos</strong>, <strong>gasterópodos</strong> y <strong>amonites</strong>.</p>

<h2>Un Testimonio del Pasado Marino</h2>

<p>Durante el <strong>Cretácico</strong>, esta región formaba parte de un <strong>ambiente marino</strong>, lo que explica la variedad de organismos que han sido preservados en el registro fósil. Los fósiles encontrados en Barbalimpia están <strong>excepcionalmente bien conservados</strong>, lo que permite a los paleontólogos estudiar la <strong>fauna marina</strong> de aquella época y comprender mejor la <strong>paleogeografía</strong> de la región.</p>

<h2>Importancia Científica y Atractivo para Aficionados</h2>

<p>El <strong>yacimiento de Barbalimpia</strong> no solo es importante para la <strong>investigación científica</strong>, sino que también atrae a <strong>aficionados a la paleontología</strong> debido a su <strong>accesibilidad</strong> y a la <strong>calidad de los fósiles</strong> que se pueden encontrar.</p>

<p>Este sitio continúa siendo una referencia clave para entender la biodiversidad marina del pasado y ofrece una experiencia única tanto para <strong>estudiosos</strong> como para <strong>visitantes interesados</strong> en la historia natural de Teruel.</p>
`
    },
    {
      id: "Iddingsita",
      title: "Iddingsita",
      date: "Sin fecha específica",
      image: iddgnisita,
      excerpt: "Un mineral de alteración originado a partir del olivino en ambientes volcánicos.",
      content: `<h1>Iddingsita: Mineral de Alteración del Olivino en Rocas Volcánicas</h1>

<p>La <strong>iddingsita</strong> es un <strong>mineral secundario</strong>, resultado de la <strong>alteración del olivino</strong> en <strong>rocas volcánicas</strong>, como basaltos y gabros. Esta transformación ocurre cuando el olivino se somete a procesos de <strong>hidratación y oxidación</strong> a <strong>bajas temperaturas</strong>, dando lugar a una mezcla compleja de minerales que suelen incluir <strong>serpentina</strong>, <strong>clorita</strong> y <strong>óxidos de hierro</strong>.</p>

<h2>Características Visuales de la Iddingsita</h2>

<p>Visualmente, la <strong>iddingsita</strong> se presenta como <strong>zonas terrosas o rojizas</strong> que sustituyen al olivino original, conservando a menudo la <strong>forma cristalina</strong> del mineral precursor. Aunque no es un mineral con valor económico directo, su presencia ofrece información valiosa sobre la <strong>historia geológica</strong> y las <strong>condiciones ambientales</strong> a las que se sometieron las rocas después de su formación.</p>

<h2>Importancia Geológica y Científica</h2>

<p>El estudio de la <strong>iddingsita</strong> ayuda a entender el <strong>ciclo evolutivo de los minerales</strong>, la <strong>interacción de la roca con fluidos hidrotermales</strong> y el impacto de procesos <strong>post-eruptivos</strong> en los entornos volcánicos. Así, este mineral es una clave para interpretar la <strong>dinámica geológica</strong> y la <strong>alteración mineralógica</strong> en zonas de <strong>actividad volcánica pasada</strong>.</p>

<p>El análisis de la iddingsita sigue proporcionando datos fundamentales para los <strong>geólogos</strong> y <strong>paleovolcanólogos</strong>, consolidándola como una herramienta valiosa en el estudio de formaciones volcánicas.</p>
`,
    },
    {
      id: "Oliverita",
      title: "Oliverita",
      date: "Sin fecha específica",
      image: oliverita,
      excerpt: "Un mineral fosfatado poco común encontrado en zonas de oxidación de yacimientos.",
      content: `<h1>Oliverita: Un Mineral Secundario de la Clase de los Fosfatos</h1>

<p>La <strong>oliverita</strong> es un <strong>mineral secundario</strong> de la clase de los <strong>fosfatos</strong>, formado habitualmente en las <strong>zonas de oxidación</strong> de diversos yacimientos minerales. Su composición incluye <strong>fosfatos hidratados de hierro y aluminio</strong>, y suele presentarse en forma de <strong>costras terrosas</strong>, <strong>masas nodulares</strong> o <strong>agregados finamente cristalinos</strong>.</p>

<h2>Rareza y Valor Científico</h2>

<p>Debido a su <strong>rareza</strong> y a las condiciones geológicas específicas que requiere para formarse, la <strong>oliverita</strong> es de especial interés para <strong>coleccionistas</strong> y <strong>geólogos</strong>. Aunque no posee un <strong>valor económico significativo</strong>, su presencia aporta pistas valiosas sobre los <strong>procesos de alteración química</strong> y <strong>meteorización</strong> que afectan a los minerales primarios, revelando el <strong>entorno geoquímico</strong> en el que se desarrolla.</p>

<h2>Importancia en el Estudio de Procesos Mineralógicos</h2>

<p>El estudio de la <strong>oliverita</strong> ayuda a comprender mejor los <strong>procesos mineralógicos</strong> que ocurren tras la formación de un depósito original, destacando el papel de las <strong>soluciones hidrotermales</strong> y la posterior <strong>oxidación y meteorización</strong> en la generación de nuevos minerales secundarios.</p>

<p>La oliverita sigue siendo una pieza clave en la investigación geológica, proporcionando datos cruciales sobre la evolución de <strong>yacimientos minerales</strong> y los ciclos de formación y transformación de minerales.</p>
 `
    },
    {
      id: "Azurita",
      title: "Azurita",
      date: "Sin fecha específica",
      image: azuita,
      excerpt: "Un mineral de cobre conocido por su intenso color azul, formándose en las zonas de oxidación de depósitos de cobre.",
      content: `<h1>Azurita: El Mineral de Cobre de Intenso Color Azul</h1>

<p>La <strong>azurita</strong> es un <strong>mineral secundario</strong>, compuesto por <strong>carbonato básico de cobre</strong>, reconocido por su característico e intenso <strong>color azul profundo</strong>. Se forma principalmente en <strong>zonas de oxidación</strong> de yacimientos de cobre, a menudo junto con la <strong>malaquita</strong>, su mineral hermano de color verde.</p>

<h2>Formas y Presentación de la Azurita</h2>

<p>Sus cristales pueden presentarse en formas <strong>prismáticas</strong>, <strong>tabulares</strong> o en <strong>agregados masivos</strong>, y sus tonalidades la convierten en una pieza muy codiciada por <strong>coleccionistas</strong> y <strong>museos</strong> de todo el mundo. Aunque no es la principal fuente de cobre, la azurita suele indicar la <strong>presencia de este metal</strong> y aporta información sobre el <strong>grado de oxidación</strong> y la <strong>circulación de fluidos</strong> en el yacimiento.</p>

<h2>Importancia Científica y Mineralógica</h2>

<p>El estudio de la <strong>azurita</strong> proporciona datos sobre la <strong>evolución del depósito</strong>, las <strong>condiciones ambientales</strong> y los <strong>procesos geológicos</strong> que influyen en la mineralogía. Asimismo, su <strong>belleza y rareza</strong> la han convertido en un mineral apreciado tanto por su <strong>valor estético</strong> como científico.</p>

<p>La azurita continúa siendo una referencia esencial en el estudio de <strong>yacimientos de cobre</strong> y representa una fascinante ventana al pasado geológico de las regiones donde se encuentra.</p>
`
    },
    {
      "id": "Amatista",
      "title": "Amatista",
      "date": "Sin fecha específica",
      "image": amatista,
      "excerpt": "Una variedad de cuarzo de color violeta, valorada por su belleza y uso como gema.",
      "content": `<h1>Amatista: La Variedad de color Violeta del Cuarzo</h1>

<p>La <strong>amatista</strong> es una <strong>variedad de cuarzo</strong> caracterizada por su distintivo <strong>color violeta</strong>, que varía desde tonos pálidos hasta un intenso púrpura. Esta tonalidad se debe a la presencia de <strong>trazas de hierro</strong> y a la exposición a la <strong>radiación natural</strong>, que altera la <strong>estructura cristalina</strong> del cuarzo puro.</p>

<h2>Formación y Geología de la Amatista</h2>

<p>Los <strong>cristales de amatista</strong> suelen formarse en <strong>cavidades de rocas volcánicas</strong>, como las <strong>geodas de basalto</strong>, donde los <strong>fluidos ricos en sílice</strong> precipitan lentamente, permitiendo el crecimiento de cristales <strong>bien definidos y transparentes</strong>. Sus bellas tonalidades la han convertido en una de las <strong>piedras preciosas</strong> más apreciadas, utilizada en <strong>joyería</strong> y como objeto ornamental desde la antigüedad.</p>

<h2>Importancia Geológica y Estudio de la Amatista</h2>

<p>Desde una perspectiva <strong>geológica</strong>, la amatista aporta información sobre las <strong>condiciones geoquímicas</strong> y térmicas durante la formación de las rocas que la albergan. Su estudio permite comprender mejor el <strong>ciclo de los silicatos</strong>, las <strong>condiciones de cristalización</strong> y el papel de <strong>elementos traza</strong> en la generación de colores y propiedades ópticas en los minerales.</p>

<p>La amatista sigue siendo una <strong>referencia esencial</strong> en el mundo de la mineralogía, cautivando tanto a <strong>científicos</strong> como a <strong>aficionados</strong> y coleccionistas.</p>
`
    },
    {
      id: "Azufre",
      title: "Azufre",
      date: "Sin fecha específica",
      image: azufre,
      excerpt: "Un elemento nativo conocido por su característico color amarillo brillante, presente en ambientes volcánicos y zonas de oxidación.",
      content: `<h1>Azufre: Elemento Nativo de Color Amarillo Brillante</h1>

<p>El <strong>azufre</strong> es un <strong>elemento nativo</strong> que se encuentra comúnmente en su <strong>forma elemental</strong>, caracterizado por su <strong>color amarillo brillante</strong> y su <strong>aspecto cristalino</strong>. Se forma en diversas condiciones geológicas, particularmente en <strong>entornos volcánicos</strong>, fumarolas y en zonas de <strong>oxidación de yacimientos sulfurosos</strong>.</p>

<h2>Propiedades y Usos del Azufre</h2>

<p>Este mineral es <strong>suave</strong>, <strong>quebradizo</strong> y <strong>arde con facilidad</strong>, desprendiendo un olor característico. Históricamente, el azufre ha sido utilizado en la <strong>fabricación de pólvora</strong>, la <strong>industria química</strong> y como <strong>fungicida en la agricultura</strong>.</p>

<h2>Importancia Geológica y Científica</h2>

<p>El estudio del <strong>azufre</strong> en una región determinada puede proporcionar pistas sobre <strong>procesos geotérmicos</strong>, la presencia de <strong>minerales sulfídicos</strong> y la interacción con <strong>aguas hidrotermales</strong>. Por ello, es un mineral clave para comprender la <strong>dinámica interna del planeta</strong>, así como para el desarrollo de diversas <strong>aplicaciones industriales y agrícolas</strong>.</p>

<p>El azufre continúa desempeñando un papel fundamental en la <strong>exploración geológica</strong> y en sectores como la <strong>energía, química y agricultura</strong>, consolidándose como un recurso indispensable en múltiples industrias.</p>
`
    },
    {
      id: "Jacinto de Compostela",
      title: "Jacinto de Compostela (Variedad de Cuarzo)",
      date: "Sin fecha específica",
      image: compostela,
      excerpt: "Una variedad distintiva de cuarzo con tonalidades rojizas, comúnmente asociada a depósitos de yeso.",
      content: `<h1>Jacinto de Compostela: La Variedad Rojiza del Cuarzo</h1>

<p>El <strong>Jacinto de Compostela</strong> es una variedad de <strong>cuarzo</strong> reconocida por su característico <strong>color rojizo a marrón oscuro</strong>, resultado de la presencia de <strong>óxidos de hierro</strong> en su estructura. Estas diminutas <strong>geodas</strong> o cristales suelen encontrarse incrustadas en formaciones de <strong>yeso</strong> y <strong>margas</strong>, particularmente en yacimientos de origen <strong>triásico</strong>.</p>

<h2>Formación y Características</h2>

<p>Su <strong>forma prismática</strong> y el intenso <strong>tono rojizo</strong> lo convierten en un mineral muy apreciado por <strong>coleccionistas</strong> y aficionados a la <strong>mineralogía</strong>. A diferencia de otras variedades de cuarzo más comunes, el <strong>Jacinto de Compostela</strong> se forma en <strong>ambientes sedimentarios</strong>, asociados a la <strong>precipitación de minerales</strong> en medios <strong>evaporíticos</strong>.</p>

<h2>Importancia Geológica y Científica</h2>

<p>El estudio de este mineral aporta información sobre el <strong>contexto geológico</strong> y las <strong>condiciones químicas</strong> que favorecen la cristalización del cuarzo y la incorporación de impurezas metálicas. Además, su <strong>singular belleza</strong> y <strong>rareza</strong> lo convierten en una joya mineralógica representativa de los <strong>procesos geológicos</strong> que dieron forma a determinados paisajes.</p>

<p>El <strong>Jacinto de Compostela</strong> no solo es una pieza de interés científico, sino también un símbolo de la riqueza mineralógica de ciertas regiones, siendo altamente valorado en <strong>museos</strong> y colecciones privadas.</p>
`
    },
    {
      id: "Calcita",
      title: "Calcita",
      date: "Sin fecha específica",
      image: calcita,
      excerpt: "Un mineral muy común compuesto de carbonato cálcico, presente en una amplia variedad de ambientes geológicos.",
      content: `<h1>Calcita: El Mineral del Carbonato de Calcio</h1>

<p>La <strong>calcita</strong> es un mineral compuesto por <strong>carbonato de calcio (CaCO₃)</strong>, reconocido por su <strong>hábito cristalino romboédrico</strong> y su amplia gama de colores, que van desde el <strong>incoloro</strong> hasta tonalidades <strong>blancas, amarillentas</strong> o incluso <strong>verdosas</strong>. Es un componente principal de las <strong>rocas sedimentarias calcáreas</strong>, como la caliza, y también puede formarse en <strong>ambientes hidrotermales</strong>, venas minerales o cuevas, dando lugar a formaciones como <strong>estalactitas</strong> y <strong>estalagmitas</strong>.</p>

<h2>Importancia Geológica de la Calcita</h2>

<p>Gracias a su <strong>abundancia</strong>, la calcita tiene gran importancia geológica, ya que participa en el <strong>ciclo del carbono</strong> y es un indicador de las <strong>condiciones químicas y ambientales</strong> en las que se forma. Desde un punto de vista económico, la calcita se emplea en la <strong>construcción</strong>, la <strong>industria química</strong>, la <strong>metalurgia</strong>, la <strong>agricultura</strong> y la <strong>fabricación de vidrio</strong>, entre otros usos.</p>

<h2>Aplicaciones Científicas y Ópticas</h2>

<p>Su estudiada <strong>birefringencia (doble refracción)</strong> también ha sido relevante en el campo científico, permitiendo el desarrollo de <strong>instrumental óptico</strong>. De este modo, la calcita juega un papel esencial tanto en la <strong>interpretación de procesos geológicos</strong> como en múltiples <strong>aplicaciones industriales</strong>.</p>

<p>La calcita sigue siendo un mineral clave para comprender los <strong>procesos geológicos</strong> y es indispensable en sectores industriales, consolidando su valor como recurso natural y científico.</p>
`
    },
    {
      id: "Yeso",
      title: "Yeso",
      date: "Sin fecha específica",
      image: yeso,
      excerpt: "Un mineral compuesto por sulfato de calcio hidratado, común en ambientes sedimentarios y evaporíticos.",
      content: `<h1>Yeso: El Mineral del Sulfato de Calcio Dihidratado</h1>

<p>El <strong>yeso</strong> es un mineral formado por <strong>sulfato de calcio dihidratado (CaSO₄·2H₂O)</strong>, caracterizado por su <strong>baja dureza</strong>, su color generalmente <strong>blanco o transparente</strong> y su <strong>hábito cristalino</strong> tabular o fibroso. Es un mineral común en <strong>ambientes sedimentarios</strong> donde la evaporación del agua favorece la <strong>precipitación de sulfatos</strong>, así como en <strong>depósitos volcánicos</strong> y en formaciones geológicas <strong>evaporíticas</strong>.</p>

<h2>Aplicaciones Industriales del Yeso</h2>

<p>Debido a su <strong>facilidad de extracción</strong> y a sus propiedades físicas, el yeso tiene múltiples aplicaciones industriales. Se utiliza en la <strong>fabricación de cemento</strong>, paneles de yeso, escayolas, <strong>fertilizantes</strong> y en la <strong>industria alimentaria</strong> como aditivo. Además, es de gran interés geológico, ya que su presencia indica <strong>cambios en el nivel del agua</strong>, la evaporación y las condiciones climáticas del pasado.</p>

<h2>Importancia Geológica y Científica</h2>

<p>El estudio del <strong>yeso</strong> también ofrece información sobre la <strong>diagénesis</strong>, los <strong>procesos sedimentarios</strong> y el <strong>ciclo geoquímico</strong> del azufre y el calcio en el medio ambiente. Su facilidad para transformarse bajo diferentes condiciones de <strong>temperatura y presión</strong> lo convierte en un mineral clave para comprender la evolución de diversos ambientes geológicos.</p>

<p>El yeso sigue siendo un elemento fundamental en la <strong>industria</strong> y en el <strong>estudio de formaciones geológicas</strong>, consolidando su relevancia tanto en el ámbito científico como en el sector productivo.</p>
 `
    },
    {
      "id": "Sílex",
      "title": "Sílex",
      "date": "Sin fecha específica",
      "image": silex,
      "excerpt": "Una variedad criptocristalina de cuarzo, conocida también como pedernal, utilizada históricamente para herramientas y armas.",
      "content": `<h1>Sílex: La Roca Criptocristalina del Dióxido de Silicio</h1>

<p>El <strong>sílex</strong>, también llamado <strong>pedernal</strong>, es una forma <strong>criptocristalina de dióxido de silicio (SiO₂)</strong>, similar a la calcedonia, pero con una textura más homogénea y compacta. Su estructura interna, formada por diminutos <strong>cristales de cuarzo</strong>, le otorga una <strong>dureza y resistencia</strong> considerables.</p>

<h2>Formación y Presencia Geológica</h2>

<p>El sílex se encuentra comúnmente en <strong>nódulos</strong> o capas incrustadas en <strong>rocas sedimentarias</strong>, especialmente en <strong>calizas</strong> y <strong>margas</strong>. Su fractura concoidea produce <strong>bordes muy afilados</strong>, lo que fue aprovechado por las <strong>sociedades prehistóricas</strong> para fabricar <strong>herramientas de corte</strong>, <strong>puntas de flecha</strong> y <strong>lanzas</strong>.</p>

<h2>Importancia Geológica e Histórica</h2>

<p>Desde el punto de vista <strong>geológico</strong>, el estudio del sílex ofrece información sobre los <strong>procesos sedimentarios</strong> y químicos que dan lugar a la <strong>silicificación</strong> en entornos <strong>marinos</strong> y <strong>continentales</strong>. Además, su <strong>importancia histórica y arqueológica</strong> es fundamental para entender la <strong>evolución tecnológica humana</strong>, ya que el dominio de su talla marcó un hito en el <strong>desarrollo cultural</strong> de numerosas civilizaciones.</p>

<p>El sílex sigue siendo una referencia clave en el estudio de <strong>yacimientos arqueológicos</strong> y <strong>formaciones geológicas</strong>, consolidando su relevancia tanto en las ciencias de la Tierra como en la arqueología.</p>
`
    },
    {
      "id": "Cuarzo Masivo",
      "title": "Cuarzo Masivo",
      "date": "Sin fecha específica",
      "image": cuarzo,
      "excerpt": "Una forma compacta y sin caras cristalinas definidas de dióxido de silicio, muy abundante en la corteza terrestre.",
      "content": `<h1>Cuarzo Masivo: La Forma Compacta del Dióxido de Silicio</h1>

<p>El <strong>cuarzo masivo</strong> es una variedad de <strong>dióxido de silicio (SiO₂)</strong> que se presenta en forma de <strong>masas compactas</strong> y sin cristales visibles a simple vista. A diferencia de los cuarzos bien cristalizados, el cuarzo masivo carece de las típicas <strong>caras y geometrías definidas</strong>, adoptando habitualmente una apariencia <strong>homogénea y opaca</strong>, en tonos <strong>blancos, grises</strong> o ligeramente <strong>translúcidos</strong>.</p>

<h2>Formación y Entornos Geológicos</h2>

<p>Esta forma de cuarzo se origina en una amplia gama de <strong>entornos geológicos</strong>, desde <strong>vetas hidrotermales</strong> hasta <strong>rocas ígneas</strong> y <strong>sedimentarias</strong>. Debido a su <strong>abundancia</strong>, se utiliza en múltiples aplicaciones, incluyendo la <strong>construcción</strong>, la <strong>industria del vidrio</strong> y la <strong>cerámica</strong>.</p>

<h2>Importancia Geológica y Aplicaciones</h2>

<p>Desde un punto de vista <strong>geológico</strong>, el cuarzo masivo es un <strong>indicador</strong> de las <strong>condiciones químicas y físicas</strong> del medio en el que cristalizó. Su estudio permite entender la <strong>evolución de las rocas</strong> que lo contienen, las interacciones con <strong>fluidos ricos en sílice</strong> y la <strong>historia tectónica y térmica</strong> de la región donde se encuentra.</p>

<p>El cuarzo masivo sigue siendo un componente esencial en la <strong>mineralogía</strong> y la <strong>industria</strong>, desempeñando un papel clave en diversos procesos geológicos y productivos.</p>
`
    },
    {
      "id": "Argonito",
      "title": "Aragonito",
      "date": "Sin fecha específica",
      "image": argonito,
      "excerpt": "Un polimorfo del carbonato de calcio, con cristales prismáticos y hábito acicular, asociado a ambientes marinos y geotermales.",
      "content": `<h1>Aragonito: El Mineral de Carbonato de Calcio con Estructura Única</h1>

<p>El <strong>aragonito</strong> es un mineral compuesto por <strong>carbonato de calcio (CaCO₃)</strong>, igual que la calcita, pero con una <strong>estructura cristalina</strong> distinta. Se presenta habitualmente en <strong>cristales prismáticos</strong>, delgados y a menudo <strong>aciculares</strong>, que pueden agruparse en <strong>agregados radiados o coralinos</strong>. Su color varía entre el <strong>blanco</strong>, el <strong>transparente</strong> y tonalidades <strong>amarillas o marrones</strong>, dependiendo de las impurezas.</p>

<h2>Formación y Entornos Geológicos</h2>

<p>El aragonito se forma en una amplia variedad de <strong>ambientes geológicos</strong>, desde <strong>depósitos marinos</strong> —donde participa en la formación del <strong>esqueleto de corales</strong> y <strong>conchas de moluscos</strong>— hasta zonas <strong>hidrotermales</strong> y <strong>cuevas</strong>, en las que las aguas ricas en calcio precipitan el mineral. Con el tiempo, el aragonito tiende a transformarse en <strong>calcita</strong>, más estable bajo condiciones superficiales.</p>

<h2>Importancia Geológica y Científica</h2>

<p>El estudio del <strong>aragonito</strong> resulta clave para comprender procesos <strong>biogénicos</strong>, <strong>sedimentarios</strong> y <strong>geotermales</strong>. Además, su <strong>presencia y distribución</strong> ofrecen indicios sobre la <strong>química del agua</strong>, la <strong>temperatura</strong>, la <strong>presión</strong> y las condiciones ambientales del pasado, haciendo de este mineral un valioso <strong>indicador del entorno geológico y climático</strong>.</p>

<p>El aragonito sigue siendo un mineral esencial en la <strong>mineralogía</strong> y la <strong>geología</strong>, proporcionando datos fundamentales sobre la evolución de ambientes marinos y terrestres.</p>
`
    },
    {
      id: "Mina de Ocre del Faro del Albir",
      title: "Mina de Ocre del Faro del Albir",
      date: "Sin fecha específica",
      image: faro,
      excerpt: "Un antiguo yacimiento en la costa alicantina, destacado por la extracción de ocre.",
      content: `<h1>Mina de Ocre del Faro del Albir: Un Tesoro de Óxidos de Hierro en Alicante</h1>

<p>La <strong>Mina de Ocre del Faro del Albir</strong>, situada en la <strong>costa de Alicante</strong>, destaca por sus antiguas labores de <strong>extracción de ocre</strong>, un material terroso compuesto principalmente por <strong>óxidos de hierro</strong> y <strong>arcillas</strong>. Este recurso, con tonalidades que varían del <strong>amarillo al marrón rojizo</strong>, se formó gracias a procesos de <strong>meteorización y oxidación</strong> de minerales ferrosos en el entorno litoral.</p>

<h2>Usos Históricos del Ocre</h2>

<p>A lo largo de la historia, el ocre extraído en esta mina fue valorado por su uso como <strong>pigmento</strong> en la elaboración de <strong>pinturas</strong>, <strong>recubrimientos</strong> y <strong>artesanías</strong>. Su <strong>riqueza cromática</strong> y la facilidad de obtención lo convirtieron en un recurso muy apreciado para usos tanto <strong>locales</strong> como <strong>regionales</strong>.</p>

<h2>Importancia Geológica y Cultural</h2>

<p>El estudio de la <strong>Mina de Ocre del Faro del Albir</strong> proporciona información sobre el <strong>contexto geológico costero</strong>, las <strong>condiciones ambientales</strong> y los <strong>procesos de alteración</strong> que dieron lugar a la formación de este depósito. Además, su exploración y documentación sirven para comprender la <strong>interacción histórica</strong> entre las <strong>comunidades humanas</strong> y los <strong>recursos naturales</strong> del Mediterráneo.</p>

<p>La mina representa un <strong>legado geológico</strong> y cultural, ofreciendo a geólogos y visitantes una ventana al pasado de la <strong>explotación de minerales</strong> en la región costera de Alicante.</p>
`
    },
    {
      id: "Dolomita",
      title: "Dolomita",
      date: "Sin fecha específica",
      image: dolomita,
      excerpt: "Un mineral compuesto por carbonato doble de calcio y magnesio, frecuente en rocas sedimentarias.",
      content: `<h1>Dolomita: El Mineral de Carbonato de Calcio y Magnesio</h1>

<p>La <strong>dolomita</strong> es un mineral formado por <strong>carbonato de calcio y magnesio (CaMg(CO₃)₂)</strong>. Presenta un <strong>hábito cristalino romboédrico</strong> similar al de la calcita, aunque suele ser algo más <strong>dura</strong> y menos <strong>soluble</strong> en ácido clorhídrico diluido. Este mineral se encuentra con frecuencia en <strong>rocas sedimentarias</strong>, como dolomías, que se originan generalmente por la <strong>alteración de calizas</strong> ricas en magnesio.</p>

<h2>Colores y Propiedades</h2>

<p>Sus colores suelen variar entre <strong>blanco</strong>, <strong>gris</strong>, <strong>crema</strong> o incluso tonos <strong>rosados</strong>, dependiendo de las impurezas presentes. La dolomita es de <strong>importancia económica</strong>, ya que se utiliza en la <strong>industria siderúrgica</strong>, en la fabricación de <strong>materiales refractarios</strong>, <strong>fertilizantes</strong> y en la <strong>construcción</strong>.</p>

<h2>Relevancia Geológica</h2>

<p>Desde el punto de vista <strong>geológico</strong>, el estudio de la dolomita ayuda a comprender procesos de <strong>diagénesis</strong> y las <strong>condiciones químicas</strong> del medio sedimentario. Su presencia indica cambios en la <strong>composición química</strong> del agua intersticial de los sedimentos <strong>marinos y continentales</strong>, aportando información valiosa sobre la <strong>historia geológica</strong> de una región.</p>

<p>La dolomita sigue siendo un mineral esencial en la <strong>geología</strong> y la <strong>industria</strong>, proporcionando datos clave para entender formaciones sedimentarias y su evolución a lo largo del tiempo.</p>
`
    },
    {
      id: "Talco",
      title: "Talco",
      date: "Sin fecha específica",
      image: talco,
      excerpt: "el mineral más blando en la escala de Mohs, con una dureza de 1, lo que permite rayarlo fácilmente con la uña.",
      content: `<h1>Talco: El Mineral del Silicato de Magnesio</h1>

<p>El <strong>talco</strong> es un mineral compuesto por <strong>silicato de magnesio hidratado (Mg₃Si₄O₁₀(OH)₂)</strong>. Es conocido por ser el mineral más <strong>blando</strong> en la escala de Mohs, con una dureza de <strong>1</strong>, lo que permite rayarlo fácilmente con la uña. Su textura es <strong>laminar y jabonosa</strong>, y su aspecto suele ser <strong>blanco</strong>, <strong>gris verdoso</strong> o <strong>amarillento</strong>, dependiendo de las impurezas presentes.</p>

<h2>Formación y Entornos Geológicos</h2>

<p>El talco se forma principalmente en <strong>entornos metamórficos</strong> a partir de la alteración de <strong>serpentina, piroxenos</strong> y <strong>anfíboles</strong> en presencia de agua y dióxido de carbono. Es común encontrarlo en <strong>esquistos</strong>, <strong>dolomías</strong> y zonas de <strong>metasomatismo</strong>. Su formación suele estar relacionada con procesos de <strong>metamorfismo de bajo grado</strong>.</p>

<h2>Aplicaciones Industriales</h2>

<p>El talco tiene una amplia gama de aplicaciones debido a su <strong>resistencia al calor</strong>, <strong>hidrofobicidad</strong> y propiedades <strong>lubricantes</strong>. Se utiliza en la fabricación de <strong>cosméticos</strong>, <strong>pinturas</strong>, <strong>papel</strong>, <strong>plásticos</strong> y <strong>cerámicas</strong>. Además, es un componente esencial en la producción de <strong>polvos industriales</strong> y productos farmacéuticos.</p>

<h2>Importancia Geológica y Científica</h2>

<p>Desde el punto de vista <strong>geológico</strong>, el talco proporciona información valiosa sobre los <strong>procesos de alteración hidrotermal</strong> y el <strong>metamorfismo de baja temperatura</strong>. Su presencia indica condiciones específicas de <strong>presión y temperatura</strong> durante la formación de las rocas que lo contienen.</p>

<p>El talco sigue siendo un mineral fundamental en la <strong>industria</strong> y la <strong>geología</strong>, desempeñando un papel clave en diversos procesos industriales y en el estudio de entornos metamórficos.</p>
`
    }

    
  ];

  const markers = [
    { id: 1, position: [38.4526526, -0.7715992], description: 'Loma Badá, ubicada en la localidad de Petrer, en la provincia de Alicante, es conocida por sus yacimientos de minerales, en particular de aragonito.', image: Petrer, title: "Argonitos en Loma Badá, Petrer", moreInfoLink: "yacimientos-mineral/Argonitos en Loma Badá, Petrer" },
    { id: 2, position: [38.5769537, -0.2589064], description: 'A lo largo de la carretera que conecta Orxeta y Finestrat, en la provincia de Alicante, se encuentran ricos yacimientos de Jacintos de Compostela.', image: Finestrat, title: "Jacintos de compostela en la carretera de Orxeta-Finestrat", moreInfoLink: "yacimientos-mineral/Jacintos de compostela en la carretera de Orxeta-Finestrat" },
    { id: 3, position: [38.4960296126089, -0.5824822022584811], description: 'La mina San Francisco, ubicada en Tibi, Alicante, es un sitio de notable relevancia debido a sus ricos yacimientos de azufre.', image: Tibi, title: "Azufre en la mina San Francisco, Tibi", moreInfoLink: "yacimientos-mineral/Azufre en la mina San Francisco, Tibi" },
    { id: 4, position: [38.5615094, -0.2186378], description: 'El depósito de Keuper, situado a lo largo de la carretera que conecta Orxeta y Finestrat en la provincia de Alicante, es conocido por sus yacimientos de calcita.', image: Orxeta, title: "Calcita en el deposito de Keuper, Carretera Orxeta-Finestrat", moreInfoLink: "yacimientos-mineral/Calcita en el deposito de Keuper, Carretera Orxeta-Finestrat" },
    { id: 5, position: [39.2391161, -1.0700520], description: 'El Cerro de Agras, situado en Cofrentes, Valencia, es un volcán de gran importancia geológica, conocido por sus yacimientos de olivino e iddingsita.', image: Cofrentes, title: "Olivino y Iddingsita Volcán Cerro de Agras, Cofrentes", moreInfoLink: "yacimientos-mineral/Olivino y Iddingsita Volcán Cerro de Agras, Cofrentes" },
    { id: 6, position: [39.5376810, -0.6282114], description: 'Un yacimiento mineral excepcional con abundancia de azurita y olivenita en Monte Horquera, Villamarchante.', image: azuita, title: "Azurita y Olivenita Monte Horquera, Villamarchante", moreInfoLink: "yacimientos-mineral/Azurita y Olivenita Monte Horquera, Villamarchante" },
    { id: 7, position: [40.10488720827497, -0.3024352921829018], description: ' Un yacimiento mineral histórico conocido por su diversidad, incluyendo galena, esfalerita y calcopirita en la Mina de San Vicente, Luchena.', image: Mina, title: "Minerales Mina de San Vicente, Luchena", moreInfoLink: "yacimientos-mineral/Minerales Mina de San Vicente, Luchena" },
    { id: 8, position: [40.5004477, -0.6858980], description: 'Un importante yacimiento fósil en Villarroya de los Pinares, conocido por su diversidad de invertebrados marinos.', image: Villarroya, title: "Yacimiento Barbalimpia, Villarroya de los Pinares", moreInfoLink: "yacimientos-mineral/Yacimiento Barbalimpia, Villarroya de los Pinares" },
    { id: 9, position: [38.5630325, -0.0515615], description: 'Un antiguo yacimiento en la costa alicantina, destacado por la extracción de ocre.', image: faro, title: "Mina de Ocre del Faro del Albir", moreInfoLink: "yacimientos-mineral/Mina de Ocre del Faro del Albir" }
  ];

  /*
   {
      id: "Ocre",
      title: "Ocre",
      date: "Sin fecha específica",
      image: "Ocre",
      excerpt: "Una mezcla natural de óxidos de hierro y arcillas, empleada históricamente como pigmento.",
      content: `
      El ocre es un material terroso constituido principalmente por óxidos de hierro hidratados y mezclas de arcillas. Presenta tonalidades que varían desde el amarillo hasta el marrón rojizo, dependiendo de su composición y del grado de hidratación del hierro. Se origina tanto en ambientes sedimentarios como en zonas de meteorización de minerales ferrosos.

      A lo largo de la historia, el ocre ha sido utilizado por diversas culturas como pigmento en pinturas rupestres, cerámicas y manifestaciones artísticas debido a su fácil extracción, estabilidad y colorido. Además, en la actualidad se sigue empleando en la industria de la pintura y la decoración por su tonalidad cálida y terrosa.

      Desde el punto de vista geológico, el estudio del ocre proporciona información sobre los procesos de oxidación, meteorización y alteración de minerales que tienen lugar en la corteza terrestre. Asimismo, su distribución geográfica e historia de uso reflejan la relación entre el ser humano y los recursos naturales del entorno.
  `
    }*/
  return (
    <Router basename="/">
      <Navbar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/inicio-yacimientos-minerales" element={<LandingPage />} />
        <Route path="/blog" element={<BlogPage posts={posts} />} />
        <Route path="/blog-yacimientos-minerales" element={<BlogPage posts={posts} />} />
        <Route path="/yacimientos-mineral/:id" element={<BlogPost posts={posts} />} />
        <Route path="/mapa-yacimientos-minerales" element={<MapPage markers={markers} />} />
        <Route path="/aviso-legal" element={<AvisoLegal />} />
        <Route path="/terminos-condiciones" element={<TerminosCondiciones />} />
        <Route path="/politica-privacidad" element={<PoliticaPrivacidad />} />
        <Route path="/politica-cookies" element={<PoliticaCookies />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
