import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/BlogList.css';
import { Helmet } from 'react-helmet';

const BlogList = ({ posts }) => {
    return (
        <div className="blog-list">
            <Helmet>
                <title>Rocas y Minerales: Nombres de minerales, Qué son, Tipos, piedras y yacimientos geológicos</title>
                <meta name="description" content="Descubre todo sobre rocas y minerales en España, incluyendo un detallado mapa de yacimientos minerales. Información clave sobre minerales y rocas, tipos, formación, localización y minerales con nombres." />
            </Helmet>
            <header className="blog-header">
                <h1>Blog</h1>
                <p>Rocas y Minerales: Nombres de minerales, Qué son, Tipos, piedras y yacimientos geológicos</p>
            </header>
            <div className="blog-posts">
                {posts.map(post => (
                    <div key={post.id} className="blog-post">
                        <img rel="preload" title={post.title} alt={post.excerpt} src={post.image} className="blog-post-image" />
                        <div className="blog-post-content">
                            <h2 className="blog-post-title">{post.title}</h2>
                            <p className="blog-post-excerpt">{post.excerpt}</p>
                            <Link to={`/yacimientos-mineral/${post.id}`} className="read-more">Leer más</Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BlogList;
