import React from 'react';
import Map from '../components/Map';
import '../styles/MapPage.css';
import { Helmet } from 'react-helmet';

const MapPage = ({ markers }) => {
    return (
        <div className="map-page">
            <Helmet>
                <title>Mapa de Yacimientos Minerales en España: Rocas y Minerales</title>
                <meta name="description" content="Descubre todo sobre rocas y minerales en España, incluyendo un detallado mapa de yacimientos minerales. Información clave sobre minerales y rocas, tipos, formación y localización." />
            </Helmet>
            <h1>Mapa yacimientos minerales españa</h1>
            <Map markers={markers} />
        </div>
    );
};

export default MapPage;
