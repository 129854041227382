import React from 'react';
import { useParams } from 'react-router-dom';
import '../styles/BlogPost.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const BlogPost = ({ posts }) => {

    const { id } = useParams();
    const post = posts.find(p => p.id === id);
/*
    if (!post) {
        return <p>Post no encontrado.</p>;
    }
*/
    return (
        <div className="blog-post-detail">
            <Helmet>
                <title>{post.title}</title>
                <meta name="description" content={post.excerpt}/>
            </Helmet>
            <header className="blog-post-header">
                <h1>{post.title}</h1>
                <p className="blog-post-date">{post.date}</p>
            </header>
            <img src={post.image} title={post.title} alt={post.excerpt} className="blog-post-detail-image" />
            <div className="blog-post-detail-content" dangerouslySetInnerHTML={{ __html: post.content }}/>
            <Link to={`/blog-yacimientos-minerales/`} className="read-more">Volver</Link>
        </div>
    );
};

export default BlogPost;
