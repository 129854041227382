import React from 'react';
import '../styles/LandingPage.css';
import heroImage from '../asserts/hero_image_optimized.jpg'; // Asegúrate de colocar una imagen en esta ruta
import featureImage1 from '../asserts/explorar.png';
import featureImage2 from '../asserts/documentacion.png';
import featureImage3 from '../asserts/gente.png';
import min1 from '../asserts/mineral1.jpeg'
import min2 from '../asserts/mineral2.jpeg'
import min3 from '../asserts/mineral3.jpeg'
import min4 from '../asserts/mineral4.jpeg'
import min5 from '../asserts/mineral5.jpeg'
import min6 from '../asserts/mineral6.jpeg'
import min7 from '../asserts/mineral7.jpeg'
import min8 from '../asserts/mineral8.jpeg'
import min9 from '../asserts/mineral9.jpeg'
import min10 from '../asserts/mineral10.jpeg'
import min11 from '../asserts/mineral11.jpeg'
import min12 from '../asserts/mineral12.jpg'
import { Helmet } from 'react-helmet';

const LandingPage = () => {
    return (
        <div className="landing-page">
            <Helmet>
                <title>Rocas y Minerales: Información Clave sobre Tipos y Formación</title>
                <meta name="description" content="Descubre todo sobre rocas y minerales en España, incluyendo un detallado mapa de yacimientos minerales. Información clave sobre minerales y rocas, tipos, formación y localización." />
            </Helmet>
            <header className="header">
                <div className="nav-container">
                    <h1 className="logo">Grupo de exploración de minerales y rocas</h1>
                    <nav className="nav">
                        <a href="#about">Nosotros</a>
                        <a href="#features">Características</a>
                        <a href="#gallery">Galería</a>
                        <a href="#contact">Contacto</a>
                    </nav>
                </div>
            </header>

            <section className="hero">
                <img rel="preload" src={heroImage} alt="Exploración de Minerales" className="hero-image" />
                <div className="hero-text">
                    <h1>Minerales, Rocas y yacimientos geológicos</h1>
                    <p>Qué son los minerales, rocas y descubre tipos de minerales.</p>
                    <a href="#features" className="cta-button">Comienza Ahora</a>
                </div>
            </section>

            <section id="about" className="about">
                <h2>Sobre Nosotros</h2>
                <p>Somos un grupo de naturalistas y aficionados a la búsqueda de yacimientos geológicos. Con esta web prentendemos compartir y localizar los yacimientos de nuestros parajes naturales para crear una comunidad donde dar a conocer lugares de interés de nuestro entorno y proveer esta información a todo el mundo.</p>
            </section>

            <section id="features" className="features">
                <h2>Características</h2>
                <div className="feature-item">
                    <img src={featureImage1} alt="Características 1" />
                    <h3>Exploración de yacimientos</h3>
                    <p>Encuentra minerales en su entorno natural, documentalos mediante fotos y descripciones detalladas.</p>
                </div>
                <div className="feature-item">
                    <img src={featureImage2} alt="Características 2" />
                    <h3>Reporta la expedición</h3>
                    <p>Mandanos a nuestro correo tus descubrimientos para que los publiquemos en el apartado de blog.</p>
                </div>
                <div className="feature-item">
                    <img src={featureImage3} alt="Características 3" />
                    <h3>Comunidad y Artículos</h3>
                    <p>Participa en artículos para difundir tus expediciones y buscar nuevas, mandando la localización del sitio.</p>
                </div>
            </section>

            <section id="gallery" className="gallery">
                <h2>Galería</h2>
                <div className="gallery-container">
                    <img src={min1} alt="mineral-hierro" className="gallery-item" />
                    <img src={min2} alt="mineral-yeso" className="gallery-item" />
                    <img src={min3} alt="mineral-yeso-rojo" className="gallery-item" />
                    <img src={min4} alt="mineral-yeso" className="gallery-item" />
                    <img src={min5} alt="mineral-cuarzo-rosa" className="gallery-item" />
                    <img src={min6} alt="mineral-dolomita" className="gallery-item" />
                    <img src={min7} alt="mineral-alglomerado-cuarzo-azul" className="gallery-item" />
                    <img src={min8} alt="mineral-argonito" className="gallery-item" />
                    <img src={min9} alt="fosil-almeja" className="gallery-item" />
                    <img src={min10} alt="fosil-amonite" className="gallery-item" />
                    <img src={min11} alt="mineral-argonito" className="gallery-item" />
                    <img src={min12} alt="mineral-calcita" className="gallery-item" />
                </div>
            </section>
        </div>
    );
};

export default LandingPage;
